
<template>
    <nav>
        <div class="nav__wrapper">
            <template  :key="name" v-for="(entry, name) in navigation">
                <template v-if="entry.type && entry.type == 'dropdown'">
                    <template v-if="entry.visible.length == 0 || (entry.visible.length > 0 && entry.visible.filter(x => $store.state.userGroups.includes(x)).length > 0)">
                        <div>
                            <div @click="entry.open = !entry.open" class="router-link router-toggle" :class="{'toggle-active': checkActiveSubEntry(entry)}">
                                <span>{{entry.name}}</span>
                                <svg v-if="entry.open" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 7.25995L7 0.999999L13 7.25995" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 1L7 7.25995L1 1" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="mobile__nav--sub" :class="{'hidden': !entry.open}">
                                <template :key="sub_name" v-for="(sub_entry, sub_name) in entry.entries">
                                    <template v-if="sub_entry.visible.length == 0 || (sub_entry.visible.length > 0 && sub_entry.visible.filter(x => $store.state.userGroups.includes(x)).length > 0)">
                                        <router-link v-if="sub_entry.to" class="router-link router-link-sub" :to="sub_entry.to">
                                            <img v-if="sub_entry.icon" :src="require(`@/assets/icons/${sub_entry.icon}`)">
                                            <span class="text">{{sub_entry.name}}</span>
                                            <span v-if="sub_entry.beta" class="beta">BETA</span>
                                            <span v-if="sub_entry.new" class="beta">NEW</span>
                                            <span v-if="sub_entry.action" class="beta">{{$t('navigation.action')}}</span>
                                        </router-link>
                                            <a v-else-if="sub_entry.url == 'https://shop.loxone.com/'" class="router-link router-link-sub" :class="{'router-link-external': sub_entry.blank}" :target="{'blank': sub_entry.blank}" :href="sub_entry.url + getShopInstance()">
                                                <img v-if="sub_entry.icon" :src="require(`@/assets/icons/${sub_entry.icon}`)">
                                                <span class="text">{{sub_entry.name}}</span>
                                                <svg v-if="sub_entry.blank" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 17 18" fill="none">
                                                    <path d="M7 4.11432H1V16.6342H13V10.4066" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4 13.5199L16 1" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M10 1H16V7.25994" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            <span v-if="sub_entry.beta" class="beta">BETA</span>
                                            <span v-if="sub_entry.new" class="beta">NEW</span>
                                            <span v-if="sub_entry.action" class="beta">{{$t('navigation.action')}}</span>
                                            </a>
                                            <a v-else-if="sub_entry.url" class="router-link router-link-sub" :class="{'router-link-external': sub_entry.blank}" :target="{'blank': sub_entry.blank}" :href="sub_entry.url">
                                                <img v-if="sub_entry.icon" :src="require(`@/assets/icons/${sub_entry.icon}`)">
                                                <span class="text">{{sub_entry.name}}</span>
                                                <svg v-if="sub_entry.blank" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 17 18" fill="none">
                                                    <path d="M7 4.11432H1V16.6342H13V10.4066" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M4 13.5199L16 1" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M10 1H16V7.25994" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                <span v-if="sub_entry.beta" class="beta">BETA</span>
                                                <span v-if="sub_entry.new" class="beta">NEW</span>
                                                <span v-if="sub_entry.action" class="beta">{{$t('navigation.action')}}</span>
                                            </a>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <template v-if="entry.visible.length == 0 || (entry.visible.length > 0 && entry.visible.filter(x => $store.state.userGroups.includes(x)).length > 0)">
                        <router-link v-if="entry.to" class="router-link" :to="entry.to">
                            <span>{{entry.name}}</span>
                        </router-link>
                        <a v-else-if="entry.url" class="router-link" :class="{'router-link-external': entry.blank}" :target="{'blank': entry.blank}" :href="entry.url">
                            <span>{{entry.name}}</span>
                            <svg v-if="entry.blank" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 17 18" fill="none">
                                <path d="M7 4.11432H1V16.6342H13V10.4066" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M4 13.5199L16 1" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10 1H16V7.25994" stroke="black" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </template>
                </template>
            </template>
        </div>
        <div class="mobile__nav">
            <a target="_blank" :href="'https://shop.loxone.com/' + getShopInstance()" class="btn--small btn">{{$t('navigation.shop')}}</a>
            <a @click="logout" class="btn--small btn btn--ghost">{{$t('navigation.logout')}}</a>
        </div>
    </nav>
</template>

<script>
    /**
     * @file Navigation.vue
     * @author @marcogabriel
     * @description Simple sidebar Navigation. The navigation objecth handle's the navigation entries. (Not all Entries are visible for each partner).
     *              • The visible array is used to check if the user is allowed to see the entry.
     *              • If the visible array is not empty, the entry is only visible for users with the given groups.
     *              • Either the url or the to key is required. The url key is used for external links.
     */

    export default {
        data() {
            return {
                navigation: {
                    'cockpit': {
                        name: this.$t('navigation.cockpit'),
                        type: 'dropdown',
                        open: false,
                        visible: [],
                        entries: {
                            'dashboard': {
                                name: this.$t('navigation.dashboard'),
                                blank: false,
                                url: '',
                                to: '/',
                                icon: 'ic-overview.svg',
                                visible: [],
                            },
                            'orders': {
                                name: this.$t('navigation.orders'),
                                blank: false,
                                url: '',
                                to: '/orders',
                                icon: 'ic-orders.svg',
                                visible: [],

                            },
                            'invoices': {
                                name: this.$t('navigation.invoices'),
                                blank: false,
                                url: '',
                                to: '/invoices',
                                icon: 'ic-invoices.svg',
                                visible: [],
                            },
                            'products': {
                                name: this.$t('navigation.products'),
                                blank: false,
                                url: '',
                                to: '/products',
                                icon: 'ic-products.svg',
                                visible: [],
                            },
                            'trainings': {
                                name: this.$t('navigation.trainings'),
                                blank: false,
                                url: '',
                                to: '/trainings',
                                icon: 'ic-trainings.svg',
                                visible: [],
                            },
                            'advantages': {
                                name: this.$t('navigation.my_advantages'),
                                blank: false,
                                url: '',
                                to: '/advantages',
                                icon: 'ic-advantages.svg',
                                visible: ['partner', 'ma'],
                            }
                        }
                    },
                    'tickets': {
                        name: this.$t('navigation.tickets'),
                        type: 'dropdown',
                        open: false,
                        visible: [],
                        entries: {
                            'new_ticket': {
                                name: this.$t('navigation.new_ticket'),
                                blank: false,
                                url: '/new-ticket/',
                                to: '',
                                icon: 'ic-new_ticket.svg',
                                visible: [],
                                new: true,
                            },
                            'my_tickets': {
                                name: this.$t('navigation.my_tickets'),
                                blank: false,
                                url: '',
                                to: '/tickets/',
                                icon: 'ic-tickets.svg',
                                visible: [],
                            },
                            'faq': {
                                name: this.$t('navigation.faq'),
                                blank: true,
                                url: this.$t('navigation.url_faq'),
                                to: '',
                                icon: 'ic-support_faq.svg',
                                visible: [],
                            },
                            'documentation': {
                                name: this.$t('navigation.documentation'),
                                blank: true,
                                url: this.$t('navigation.url_documentation'),
                                to: '',
                                icon: 'ic-documentation.svg',
                                visible: [],
                            },
                        }
                    },
                    'marketing_tools': {
                        name: this.$t('navigation.marketing_tools'),
                        type: 'dropdown',
                        open: false,
                        visible: ['partner'],
                        entries: {
                            'website': {
                                name: this.$t('navigation.partner_website'),
                                blank: false,
                                url: '',
                                to: '/website/',
                                icon: 'ic-website.svg',
                                visible: ['partner'],
                                beta: false,
                            },
                            'references': {
                                name: this.$t('navigation.references'),
                                blank: false,
                                url: '',
                                to: '/references/',
                                icon: 'ic-references.svg',
                                visible: ['partner'],
                                beta: false,
                            },
                            'mujdum': {
                                name: 'Můj dům',
                                blank: true,
                                url: 'https://mujdum.loxone.com',
                                to: '',
                                icon: 'ic-planning.svg',
                                visible: this.$i18n.locale == 'cscz' ? ['partner'] : ['hidden'],
                                beta: true,
                            },
                            'det_es': {
                                name: 'Dig. Experience Tour',
                                blank: true,
                                url: 'https://www.loxone.com/eses/info/digital-experience-tour/',
                                to: '',
                                icon: 'ic-products.svg',
                                visible: this.$i18n.locale == 'eses' ? ['partner'] : ['hidden'],
                                beta: false,
                            },
                            'det_it': {
                                name: 'Dig. Experience Tour',
                                blank: true,
                                url: 'https://www.loxone.com/itit/lp/digital-experience-tour/',
                                to: '',
                                icon: 'ic-products.svg',
                                visible: this.$i18n.locale == 'itit' ? ['partner'] : ['hidden'],
                                beta: false,
                            },
                            'det_fr': {
                                name: 'Dig. Experience Tour',
                                blank: true,
                                url: 'https://www.loxone.com/frfr/infos/digital-experience-tour/',
                                to: '',
                                icon: 'ic-products.svg',
                                visible: this.$i18n.locale == 'frfr' ? ['partner'] : ['hidden'],
                                beta: false,
                            },
                            'det_cz': {
                                name: 'Dig. Experience Tour',
                                blank: true,
                                url: 'https://www.loxone.com/cscz/lp/digital-experience-tour/',
                                to: '',
                                icon: 'ic-products.svg',
                                visible: this.$i18n.locale == 'cscz' ? ['partner'] : ['hidden'],
                                beta: false,
                            },
                            'det_sk': {
                                name: 'Dig. Experience Tour',
                                blank: true,
                                url: 'https://www.loxone.com/sksk/lp/digital-experience-tour/',
                                to: '',
                                icon: 'ic-products.svg',
                                visible: this.$i18n.locale == 'sksk' ? ['partner'] : ['hidden'],
                                beta: false,
                            },
                            'det_pl': {
                                name: 'Dig. Experience Tour',
                                blank: true,
                                url: 'https://www.loxone.com/plpl/lp/digital-experience-tour/',
                                to: '',
                                icon: 'ic-products.svg',
                                visible: this.$i18n.locale == 'plpl' ? ['partner'] : ['hidden'],
                                beta: false,
                            },
                        }
                    },
                    'partner_infos': {
                        name: this.$t('navigation.partner_infos'),
                        type: 'dropdown',
                        open: false,
                        visible: ['partner'],
                        entries: {
                            'info_downloads': {
                                name: this.$t('navigation.info_downloads'),
                                blank: true,
                                url: '',
                                to: '/info/downloads/',
                                icon: 'ic-downloads.svg',
                                visible: ['partner'],
                            },
                            'partner_programm': {
                                name: this.$t('navigation.partner_programm'),
                                blank: true,
                                url: this.$t('navigation.url_programm'),
                                to: '',
                                icon: 'ic-partner_programm.svg',
                                visible: ['partner'],
                            },
                            'partner_guidelines': {
                                name: this.$t('navigation.partner_guidelines'),
                                blank: true,
                                url: this.$t('navigation.url_guidelines'),
                                to: '',
                                icon: 'ic-partner_guidelines.svg',
                                visible: ['partner'],
                            },
                            'webinar_pl': {
                                name: 'Webinars',
                                blank: true,
                                url: 'https://www.loxone.com/plpl/lp/webinary-tematyczne/',
                                to: '',
                                icon: 'ic-trainings.svg',
                                visible: this.$i18n.locale == 'plpl' ? ['partner'] : ['hidden'],
                            },
                        }
                    },
                    'ek_infos': {
                        name: this.$t('navigation.partner_infos'),
                        type: 'dropdown',
                        open: false,
                        visible: ['endcustomer'],
                        entries: {
                            'become_partner': {
                                name: this.$t('navigation.become_partner'),
                                blank: true,
                                url: this.$t('navigation.url_become_partner'),
                                to: '',
                                icon: 'ic-become_partner.svg',
                                visible: ['partner'],
                            },
                            'find_partner': {
                                name: this.$t('navigation.find_partner'),
                                blank: true,
                                url: this.$t('navigation.url_find_partner'),
                                to: '',
                                icon: 'ic-find_partner.svg',
                                visible: ['partner'],
                            },
                        }
                    },
                    'additional_tools': {
                        name: this.$t('navigation.additional_tools'),
                        type: 'dropdown',
                        open: false,
                        visible: [],
                        entries: {
                            'webshop': {
                                name: this.$t('navigation.shop'),
                                blank: true,
                                url: 'https://shop.loxone.com/',
                                to: '',
                                icon: 'ic-webshop.svg',
                                visible: [],
                            },
                            'configurator': {
                                name: 'Configurator',
                                blank: true,
                                url: 'https://configurator.loxone.com',
                                to: '',
                                icon: 'ic-configurator.svg',
                                visible: [],
                            },
                            'library': {
                                name: 'Library',
                                blank: true,
                                url: 'https://library.loxone.com',
                                to: '',
                                icon: 'ic-library.svg',
                                visible: [],
                            },
                            'academy': {
                                name: 'Academy',
                                blank: true,
                                url: this.$t('navigation.url_academy'),
                                to: '',
                                icon: 'ic-education.svg',
                                visible: [],
                            },
                            'campus': {
                                name: 'Loxone Campus',
                                blank: true,
                                url: 'https://www.loxone-campus.com/',
                                to: '',
                                icon: 'ic-campus.svg',
                                visible: [],
                            },
                            'partnerPackages': {
                                name: 'Campus Packages',
                                blank: false,
                                url: '/campus-packages/',
                                to: '',
                                icon: 'ic-campus.svg',
                                visible: [],
                            },
                            'settings': {
                                name: this.$t('navigation.settings'),
                                blank: false,
                                url: '',
                                to: '/settings/',
                                icon: 'ic-settings.svg',
                                visible: [],
                            },
                        }
                    }
                }
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
                .catch(err => console.log(err))
            },
            getShopInstance() {
                let checkNAVInstance = (this.$store.state.userData.content.account_nav_instance || this.$store.state.userData.content.contact_nav_instance);
                if(this.$store.state.userData.loaded && checkNAVInstance) {
                    if(checkNAVInstance == 'CH') {
                        if(this.$i18n.locale == 'frfr') {
                            return 'frch';
                        } else {
                            return 'dech';
                        }
                    } else if(checkNAVInstance == 'UK') {
                        return 'enuk';
                    } else if(checkNAVInstance == 'US') {
                        return 'enus';
                    } else if(checkNAVInstance == 'AT') {
                        let country_switch = this.$store.state.userData.content.account_country || this.$store.state.userData.content.contact_country;
                        switch(country_switch) {
                            case 'AT':
                                return 'deat';
                            case 'DE':
                                return 'dede';
                            case 'ES':
                            case 'CA':
                                return 'eses';
                            case 'FR':
                                return 'frfr';
                            case 'IT':
                                return 'itit';
                            case 'NL':
                            case 'BE':
                            case 'LU':
                                return 'nlnl';
                            case 'SK':
                                return 'sksk';
                            case 'PL':
                                return 'plpl';
                            case 'CZ':
                                return 'cscz';
                            default:
                                return 'enen';
                        }
                    } else {
                        return '';
                    }
                }
            },
            toggleSidebar(){
                this.$store.dispatch('toggleSidebar');
            },
            checkActiveSubEntry(entry) {
                for (const [key, value] of Object.entries(entry.entries)) {
                    if(value.to == this.$route.fullPath) {
                        return true;
                    }
                }
                
                return false;
            }
        },
        created() {

            let checkNAVInstance = (this.$store.state.userData.content.account_nav_instance || this.$store.state.userData.content.contact_nav_instance);

            if(checkNAVInstance == 'CH') {
                if(this.$i18n.locale == 'frfr') {
                    this.navigation.partner_infos.entries.partner_programm.url = 'https://www.loxone.com/frfr/infos/programme-partenaires-ch/';
                } else {
                    this.navigation.partner_infos.entries.partner_programm.url = 'https://www.loxone.com/dede/partnerprogramm-mai-2022-schweiz/';
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
	@import "@/scss/modules/_nav.scss";

    .beta{
        background: #69C350;
        display: inline-block;
        line-height: 24px;
        color: white;
        padding: 0 3px;
        font-size: 12px;
        vertical-align: middle;
        margin-left: 5px;
    }
</style>